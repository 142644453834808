<template>
  <b-container class="main__page" fluid>
    <b-row>
      <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>
      <b-col cols="12" sm="12" md="12" lg="10" xl="8">
        <h1 class="main__heading">Login</h1>
        <p class="main__content">Currently, Moonjelly MVP is being tested by a limited first-access audience. We are working to open it up for a wider group of beta-testers. If you would like to become part of it, please subscribe, and as soon as we are ready, we will get you engaged. We look forward to working with you soon! Thank you for your interest in our new mutual feature of privacy solutions.</p>
        <moonjelly-card 
          class="mj-card-style text-center"
          headermain = "SIGN UP FOR BETA-ACCESS"
        >
          <b-form @submit.prevent="onSubmit" v-if="show" class="contact__form-body">
            <b-form-group id="input-group-5">
              <b-form-input
                id="input-5"
                v-model="form.surname"
                type="text"
                autocomplete="nope"
                placeholder="Your name"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-1">
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                required
                placeholder="Email"
              ></b-form-input>
            </b-form-group>
            <b-button
              type="submit"
              variant="outline-primary"
              class="custom_btn mt-3"
              pill
              >SIGN UP
            </b-button>
            <p class="mt-5 ">
              By clicking "sign up", you agree to receive a beta-access newsletter.
            </p>
          </b-form>
          <b-row v-if="!show" class="contact__form-body align-items-center">
            <b-col>Thank you for signing up! We will contact you soon. Stay safe!</b-col>
          </b-row>
        </moonjelly-card>
      </b-col>
      <b-col cols="0" sm="0" md="0" lg="1" xl="2"/>    
    </b-row>
  </b-container>
</template>

<script>
  import moonjellyCard from '@/components/MoonjellyCard.vue'

  export default {
    components: {
      moonjellyCard
    },
    data() {
      return {
        show: true,
        form: {
          surname: '',
          email: '',
          checked: ['agree']
        },
        contactMsg: ''
      }
    },
    methods: {
      onSubmit: async function() {
        this.show = false
        this.contactMsg = 'Thank you for signing up! We look forward to work with you as soon as beta product is ready. Stay safe!'
        try {
          // this token below is fake recaptcha
          this.form.token = '03AGdBq267meY1qVZj9bCNSWuKC2r-Te6r_zP4omulpN7rWLM8eeej31SkqquOnZSef0Qm7pBkE_LHqzn0NS7G9c3pvDAW4tlUYuCYW1B0bozcmC-A_Bmx565-3mBhWQvV-klmjciOpJarBT0e6yd4rAzjUqELRwKKN9QYo2g2bi3DR6'
          this.form.surname = 'Doesub'
          await fetch('https://hook.integromat.com/sd39678hgt4hwvz2j60pyeh0eb3bdq46', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.form)
          })
          setTimeout(() => {
            this.show = true         
            this.form.email = ''
          }, 4000)
        } catch(e) {
          this.contactMsg = 'Something went wrong! Please reload the page and try again'
        }
      }
    }
  }


</script>

<style lang="scss" scoped>
.main__page {
  min-height: 100vh;
  background-color: #ccdae8;
  padding-bottom: 70px;
}

.main__heading {
  color: $blue;
  text-transform: uppercase;
  font-family: "Good Sans Regular";
  padding: 50px 0 50px 0 !important;
}

.main__content {
  font-size: $content-font-size;
  font-family: "Good Sans Regular";
  color: #000;
  padding-bottom: 50px;
}

.mj-card-style {
  max-width: 600px; 
  margin: auto;
}

.contact__form-body {
  font-family: 'Good Sans Regular';
  color: #0500ff;
  padding: 15px;
  font-size: 15px;
  min-height: 230px;
}

.form-control {
  border: none;
  border-bottom: 1px solid #0500ff;
  border-radius: 0;
  color: #0500ff;
  font-size: 0.8rem;
}
.form-control:focus {
  border: none !important;
  border-bottom: 2px solid #0500ff !important;
  color: #0500ff !important;
}
.form-control::placeholder {
  color: #0400f050 !important;
}

.form-control::placeholder {
  color: #0500ff;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #0500ff;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #0500ff;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #0500ff;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: rgb(5, 0, 255);
}

#input-group-5 {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.custom_btn {
  width: 200px;
}


</style>